<template>
  <div>
    <div class="row1">
      <el-autocomplete
        v-model="search"
        prefix-icon="el-icon-search"
        :fetch-suggestions="querySearchAsync"
        placeholder="请输入内容"
        @select="handleSelect"
        style="width: 350px"
        :trigger-on-focus="false"
      ></el-autocomplete>
      <div>经度：{{ currentCoordinate.lng }} 纬度：{{ currentCoordinate.lat }}</div>
    </div>
    <baidu-map @ready="handler" class="map" :scroll-wheel-zoom="true" ak="$ak" @click="getClickInfo"></baidu-map>
  </div>
</template>

<script>
import { BaiduMap } from 'vue-baidu-map'
export default {
  props: {
    lng: {
      type: Number,
      default: 116.404
    },
    lat: {
      type: Number,
      default: 39.91
    },
    search: {
      type: String,
      default: () => ''
    }
  },
  components: {
    BaiduMap
  },
  data: function () {
    return {
      // search: null,
      currentCoordinate: { lng: 116.404, lat: 39.91 },
      BMap: null,
      map: null,
      address: null
    }
  },
  methods: {
    // 父组件点击加载
    showDo() {
      let that = this
      setTimeout(() => {
        let options = {
          onSearchComplete: function (results) {
            if (local.getStatus() === 0) {
              let x = results.getPoi(0)
              let item = { value: x.title, point: x.point, address: x.address }
              that.currentCoordinate.lng = x.point.lng
              that.currentCoordinate.lat = x.point.lat
              that.handleSelect(item)
            }
          }
        }
        let local = new this.BMap.LocalSearch(this.map, options)
        local.search(this.search)
      }, 100)
    },
    handler({ BMap, map }) {
      this.map = map
      this.BMap = BMap
      let point = new this.BMap.Point(this.lng ? this.lng : 116.404, this.lat ? this.lat : 39.91)
      this.currentCoordinate = { lng: this.lng ? this.lng : 116.404, lat: this.lat ? this.lat : 39.91 }
      let marker = new this.BMap.Marker(point)
      map.centerAndZoom(point, 15)
      // map.addOverlay(marker);
      // let circle = new this.BMap.Circle(point, 1000, {
      //   strokeColor: 'blue',
      //   strokeWeight: 2,
      //   strokeOpacity: 0.5,
      // });
      // map.addOverlay(circle);
    },
    getClickInfo(e) {
      this.currentCoordinate.lng = e.point.lng
      this.currentCoordinate.lat = e.point.lat
    },
    querySearchAsync(queryString, callback) {
      let options = {
        onSearchComplete: function (results) {
          let arr = []
          if (local.getStatus() === 0) {
            // 判断状态是否正确
            for (let i = 0; i < results.getCurrentNumPois(); i++) {
              let x = results.getPoi(i)
              let item = { value: x.title, point: x.point, address: x.address }
              arr.push(item)
            }
          }
          callback(arr)
        }
      }
      let local = new this.BMap.LocalSearch(this.map, options)
      local.search(queryString)
    },
    handleSelect(item) {
      if (this.map) {
        this.map.clearOverlays()
        let marker = new this.BMap.Marker(item.point)
        this.map.addOverlay(marker)
        let opts = {
          width: 200, // 信息窗口宽度
          height: 100, // 信息窗口高度
          title: item.value // 信息窗口标题
        }
        let infoWindow = new this.BMap.InfoWindow(`地址：${item.address}`, opts) // 创建信息窗口对象
        marker.addEventListener('click', function () {
          this.map.openInfoWindow(infoWindow, item.point) //开启信息窗口
        })
        this.map.centerAndZoom(item.point, 15)
        this.$emit('getValue', item)
        this.currentCoordinate.lng = item.point.lng
        this.currentCoordinate.lat = item.point.lat
        const myGeo = new this.BMap.Geocoder()
        let that = this
        myGeo.getLocation(item.point, function (result) {
          that.address = result.addressComponents
        })
      }
    }
  },
  watch: {
    lng() {
      this.map.clearOverlays()
      this.currentCoordinate = { lng: this.lng ? this.lng : 116.404, lat: this.lat ? this.lat : 39.91 }
      let point = new this.BMap.Point(this.lng ? this.lng : 116.404, this.lat ? this.lat : 39.91)
      let marker = new this.BMap.Marker(point)
      this.map.centerAndZoom(point, 15)
      this.map.addOverlay(marker)
    },
    lat() {
      this.map.clearOverlays()
      this.currentCoordinate = { lng: this.lng ? this.lng : 116.404, lat: this.lat ? this.lat : 39.91 }
      let point = new this.BMap.Point(this.lng ? this.lng : 116.404, this.lat ? this.lat : 39.91)
      let marker = new this.BMap.Marker(point)
      this.map.centerAndZoom(point, 15)
      this.map.addOverlay(marker)
    }
  }
}
</script>

<style scope lang="less">
.row1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.map {
  width: 100%;
  height: 500px;
  margin-top: 20px;
}
.BMap_cpyCtrl {
  display: none;
}
.anchorBL {
  display: none;
}
</style>
